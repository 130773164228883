.page-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 7990;
  background: $color_white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
  &--wrapper {
  }
  &--wrap {
    display: flex;
    align-items: center;
    height: 80px;
  }
  &--item {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    justify-content: space-between;
  }
  &--inner {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  &--logo {
    display: block;
    width: auto;
    height: 60px;
    object-fit: contain;
    &--link {
      display: block;
      margin: 0 -10px;
      margin-right: 40px;
      padding: 10px;
      background: $color_white;
    }
  }
  &--link {
    display: block;
    padding: 2px 0;
    color: $color_black;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    &--admin {
      position: relative;
      margin-right: -10px;
      margin-left: 10px;
      padding: 5px 10px;
      color: $color_main;
      transition: all 300ms ease;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        background: #ddddde;
      }
      svg {
        width: 15px;
        transition: all 300ms ease;
      }
    }
  }
  &--phone {
    display: grid;
    align-items: center;
    grid-template-columns: 16px auto;
    grid-gap: 17px;
    color: $color_main;
    &--links {
    }
  }
  &--menu {
    &--button {
      display: none;
      align-items: center;
      justify-content: center;
      padding: 10px;
      color: $color_black;
      border: none;
      outline: none;
      background: none;
    }
    &--open {
      margin: 0 0 0 auto;
      .icon {
        width: 30px;
        margin: -4px 0;
      }
    }
    &--close {
      position: fixed;
      top: 0;
      right: 260px;
      opacity: 0;
      transition: all 300ms ease 300ms;
      pointer-events: none;
      &:before {
        content: '';
        position: fixed;
        top: 0;
        right: 260px;
        width: 100vw;
        height: 100vh;
        background: rgba($color_black, 0.2);
        cursor: default;
      }
      .icon {
        width: 20px;
      }
    }
  }
  &--homepage {
    box-shadow: none;
  }
  .container {
    max-width: 1366px;
  }
  @media (max-width: 1199px) {
    &--logo {
      &--link {
        margin-right: 0;
      }
    }
    &--phone {
      grid-gap: 8px;
    }
  }
  @media (max-width: 1023px) {
    &--menu {
      &--button {
        display: inline-flex;
      }
    }
    &--item {
      position: fixed;
      top: 0;
      right: 0;
      overflow: auto;
      align-items: stretch;
      flex-direction: column;
      width: 260px;
      height: 100vh;
      background: $color_white;
      transition: all 300ms ease;
      transform: translate(100%, 0);
      &.js-show {
        box-shadow: 2px 0 10px 0 #5a5a5a;
        transform: none;
        .page-header--menu--close {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
    &--inner {
      justify-content: space-between;
      margin: 0;
      padding: 16px;
    }
  }
  @media (max-width: 767px) {
    &--wrap {
      height: 70px;
    }
    &--logo {
      height: 50px;
    }
  }
}

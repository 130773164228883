// SVG ikony
.icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  &:before {
    content: '';
    display: block;
  }
  &--rotate {
    &--90 {
      transform: rotate(90deg);
    }
    &--180 {
      transform: rotate(180deg);
    }
    &--270 {
      transform: rotate(270deg);
    }
  }
}
// vnitřní box s ikonou
.icon__svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //fill: currentColor;
  //stroke: currentColor;
  pointer-events: none;
  //transform: translateZ(0);
}
// propojení jednotlivých ikon
@import "icons/icons.scss";

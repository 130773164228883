// základní nastavení typografie
// nadpisy
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  color: $color_secondary;
  font-weight: 700;
  line-height: 1.3;
  &:last-child {
    margin-bottom: 0;
  }
}
h1 {
  font-size: 32px;
  @media (max-width: 479px) {
    font-size: 28px;
  }
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 16px;
  font-weight: 600;
}
h4 {
  font-size: 1rem;
}
// odkazy
a {
  color: $color_main;
  &:hover,
  &:focus {
    color: $color_main_dark;
  }
}
// odstavce
p {
  margin-top: 0;
  color: #636d79;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.75;
  &:last-child {
    margin-bottom: 0;
  }
}
// seznamy
ul,
ol {
  margin: 0 0 10px 0;
  padding: 0 0 0 20px;
  line-height: 1.4;
  li {
    padding: 3px 3px 3px 0;
  }
  ul,
  ol {
    margin: 0;
    padding: 3px 3px 0 10px;
    li {
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
ul:not([class]) {
  margin: 20px 0 40px;
  padding: 0;
  list-style: none;
  color: #636d79;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  li {
    position: relative;
    padding-left: 15px;
    &:before {
      content: '';
      position: absolute;
      top: 12px;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 100px;
      background: $color_main;
    }
  }
}
ol:not([class]) {
  margin: 20px 0 40px;
  padding: 0;
  list-style: none;
  color: #636d79;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  counter-reset: section;
  li {
    padding: 6px 0;
    &:before {
      content: counter(section);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 31px;
      height: 31px;
      margin: 0 10px 0 0;
      color: $color_white;
      font-size: 13px;
      font-weight: 700;
      line-height: 1;
      border-radius: 100px;
      background: $color_main;
      counter-increment: section;
    }
  }
}
.headline {
  &--sub {
    position: relative;
    display: inline-block;
    color: #f81157;
    font-size: 18px;
    font-weight: 600;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      width: 120px;
      height: 1px;
      background: rgba(#707070, 0.27);
      transform: translate(8px, -50%);
    }
    + span {
      display: block;
    }
  }
}

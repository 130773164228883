.main-signpost {
  position: relative;
  z-index: 4;
  &--wrapper {
    display: flex;
    background: rgba(#000, 0.7);

    backdrop-filter: blur(50px);
  }
  &--primary {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    flex: 0 1 671px;
    justify-content: flex-end;
    padding: 0 10px;
    font-weight: 400;
  }
  &--secondary {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex: 0 0 401px;
    padding: 0 10px;
    font-weight: 700;
    background: linear-gradient(to top, #fa1254 0%, #e80967 100%);
    .main-signpost--item:hover:before {
      background: rgba(0, 0, 0, 0.7);

      backdrop-filter: blur(50px);
    }
  }
  &--item {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 150px;
    margin: 0;
    padding: 0;
    color: $color_white;
    font-weight: inherit;
    text-align: center;
    text-decoration: none;
    border: none;
    outline: none;
    background: none;
    &:hover {
      color: $color_white;
      cursor: pointer;
      &:before {
        transform: scaleY(1);
      }
      .main-signpost--hidden {
        opacity: 1;
        transform: translate(0, 0);
        pointer-events: auto;
      }
      .icon {
        transform: translate(0, -5px);
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 8px;
      right: 0;
      bottom: 0;
      left: 0;
      background: $color_main;
      transition: all 300ms ease;
      transform: scaleY(0);
      transform-origin: center bottom;
    }
  }
  &--icon {
    width: 100%;
    height: 41px;
    margin-top: 1em;
    color: currentColor;
    transition: all 300ms ease;
  }
  &--text {
    position: relative;
    display: block;
    height: 2em;
    margin: 12px 0 0;
    font-size: 14px;
    font-weight: inherit;
  }
  &--hidden {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 2;
    margin-top: -8px;
    padding: 0 5px 24px;
    text-align: center;
    background: $color_main;
    opacity: 0;
    transition: all 300ms ease;
    transform: translate(0, -10px);
    pointer-events: none;
    &--link {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      color: $color_white;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.71;
      text-decoration: none;
      border: none;
      outline: none;
      background: none;
      &:hover {
        color: currentColor;
        text-decoration: underline;
      }
    }
    &--divider {
      display: block;
      width: 100%;
      height: 1px;
      margin: 10px auto;
      background: rgba($color_white, 0.24);
    }
  }
  &--settings {
    position: absolute;
    top: 50%;
    left: -22px;
    z-index: 1;
    display: grid;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    color: $color_secondary;
    border: none;
    border-radius: 100px;
    outline: none;
    background: $color_white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.42);
    transform: translate(0, -50%);
    &:hover {
      .main-signpost--settings--tooltip {
        opacity: 1;
      }
    }
    &--tooltip {
      position: absolute;
      bottom: 100%;
      left: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      height: 37px;
      margin-bottom: 3px;
      padding: 5px 13px;
      color: $color_white;
      font-size: 13px;
      font-weight: 600;
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.8);
      opacity: 0;
      transition: all 300ms ease;
      pointer-events: none;
    }
  }
  .container {
    max-width: 1092px;
  }
  @media (max-width: 1199px) {
  }
  @media (max-width: 1023px) {
    &--wrapper {
      flex-direction: column;
    }
    &--primary {
      flex: 1 1 auto;
      padding: 0;
    }
    &--secondary {
      flex: 1 1 auto;
      padding: 0;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    &--secondary {
      grid-template-columns: 1fr 1fr 1fr 0.6fr;
      background: none;
      .main-signpost {
        &--item {
          background: linear-gradient(to top, #fa1254 0%, #e80967 100%);
        }
      }
    }
    &--settings {
      position: static;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: auto;
      height: 150px;
      margin: 0;
      padding: 0;
      color: #fff;
      font-weight: inherit;
      text-align: center;
      text-decoration: none;
      border: none;
      outline: none;
      background: none;
      box-shadow: none;
      transform: none;
      &--tooltip {
        position: static;
        display: block;
        width: auto;
        height: 2em;
        margin: 12px 0 0;
        font-size: 14px;
        font-weight: inherit;
        background: none;
        opacity: 1;
      }
      .icon {
        width: 41px;
        margin-top: 1em;
        color: currentColor;
        transition: all 300ms ease;
      }
    }
  }
  @media (max-width: 767px) {
    .container {
      padding: 0;
    }
    &--primary {
      display: none;
      //grid-template-columns: 1fr 1fr 1fr;
    }
    &--settings {
      top: -22px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    &--item {
      height: 120px;
      &:before {
        top: 7px;
      }
      &:nth-child(4) {
        &:after {
          display: none;
        }
      }
    }
  }
}

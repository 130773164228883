.reference {
  margin: 80px 0;
  h1 {
    margin: 0;
    line-height: 1.63;
    text-shadow: none;
  }
  &--wrapper {
  }
  &--slider {
    margin: 10px 0 0;
    padding: 0 38px;
  }
  &--item {
    padding: 10px;
  }
  &--img {
    max-width: 100%;
    margin: auto;
  }
  .slick-arrow {
    width: 38px;
    background: none;
    .icon {
      left: 10px;
      color: $color_secondary;
    }
  }
  @media (max-width: 767px) {
    .container {
      padding: 0;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  margin: -8px;
  &--button {
    margin: 0;
    padding: 8px;
    color: #18274d;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.57;
    text-decoration: none;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
  }
  &--arrow {
    color: $color_main;
  }
  &--numbers {
    display: flex;
    align-items: center;
  }
  &--number {
  }
  &--divider {
    padding: 8px;
  }
  .js-active {
    color: $color_main;
    font-weight: 700;
  }
  @media (max-width: 479px) {
    margin: 0 -8px;
  }
}

$page-footer-map-1: #17140a;
.page-footer-map {
  overflow: hidden;
  padding: 55px 0 85px;
  color: $page-footer-map-1;
  font-size: 1rem;
  font-weight: 700;
  line-height: normal;
  background: #efefef;
  .container {
    max-width: 1150px;
  }
  .list-check {
    &--ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 -15px;
      padding: 0;
      list-style: none;
      &--1 {
        flex-direction: column;
        padding: 30px 0;
        .list-check {
          &--li {
            flex-basis: auto;
          }
        }
      }
    }
    &--li {
      flex: 0 0 calc(100% / 3);
      margin-bottom: 15px;
      padding: 0;
    }
    &--wrap--2 {
      padding: 30px 0;
    }
    @media (max-width: 479px) {
      &--li {
        flex: 0 0 100%;
      }
    }
  }
  .t {
    font-weight: 700;
    &-1 {
      font-size: 0.875rem;
    }
    &-2 {
      font-size: 1rem;
    }
    &-3 {
      font-size: 1.25rem;
    }
    &-4 {
      font-size: 1.875rem;
    }
    &-5 {
      font-size: 2.813rem;
    }
    &-6 {
      font-size: 0.688rem;
    }
    &-7 {
      font-size: 0.75rem;
    }
    &-8 {
      font-size: 1.125rem;
    }
    &-9 {
      font-size: 0.938rem;
    }
    &-10 {
      font-size: 1.375rem;
    }
    &-11 {
      font-size: 2.5rem;
    }
  }
  .fw-400 {
    font-weight: 400;
  }
  .fw-500 {
    font-weight: 500;
  }
  .fw-700 {
    font-weight: 700;
  }
  .checkbox--label {
    display: inline-block;
  }
  &--headline-1 {
    margin-bottom: 30px;
    color: #000;
    font-size: 1.875rem;
    line-height: 1.67;
  }
  &--headline-2 {
    margin-bottom: 15px;
    color: #000;
  }
  &--text-1 {
    color: fade($page-footer-map-1, 70);
    font-weight: normal;
    opacity: 0.7;
  }
  &--link-1 {
    font-weight: normal;
    text-decoration: underline;
  }
  &--button--1 {
    display: inline-block;
    margin-top: auto;
    padding: 13px 17px;
    color: #fff;
    font-size: 0.875rem;
    font-weight: bold;
    vertical-align: middle;
    text-decoration: none;
    border: none;
    background: $color_main;
    box-shadow: 0 1.25rem 1.875rem rgba($color_main, 0.19), 0 0.5rem 0.75rem rgba($color_main, 0.37);
    transition: all 300ms ease;
    &:hover {
      text-decoration: none;
      background: fade($color_main, 80%);
    }
  }
  &--label {
    display: block;
    margin-bottom: 5px;
    color: rgba(#59574e, 0.6);
    font-size: 0.875rem;
    font-weight: normal;
  }
  &--input {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 50px;
    margin-bottom: 15px;
    padding: 17px;
    font-size: 0.875rem;
    font-weight: normal;
    border: none;
    background: #fff;
  }
  &--wrapper {
    margin-top: 40px;
  }
  &--wrap {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
  }
  &--item {
    flex: 1 0 450px;
    max-width: 500px;
    padding: 0 15px;
    &:first-child {
      display: flex;
      align-items: flex-start;
      flex: 1 1 auto;
      flex-direction: column;
      max-width: none;
    }
  }
  &--inner {
    padding: 10px 15px;
    &--wrap {
      display: flex;
      flex-wrap: wrap;
      margin: -10px -15px 10px;
    }
  }
  &--form {
    &--wrap {
      display: flex;
      justify-content: space-between;
      margin: 0 -8px;
    }
    &--item {
      flex: 1 1 50%;
      padding: 0 8px;
    }
  }
  &--soc {
    &--wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    &--link {
      display: inline-block;
      padding: 5px;
      text-decoration: none;
    }
    &--icon {
      height: 40px;
    }
  }
  &--map {
    &--container {
      position: relative;
    }
    &--button {
      position: absolute;
      top: 20px;
      right: 0;
      z-index: 999;
    }
    .map {
      height: 500px;
      max-height: 100vh;
    }
  }
  &--toggle {
    &--checkbox:checked + &--label {
      &:after {
        transform: translate(100%, -50%);
      }
    }
    &--label {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      padding-right: 15px;
      color: $page-footer-map-1;
      font-family: Roboto, Montserrat, sans-serif;
      font-size: 1rem;
      font-weight: normal;
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 100%;
        border-radius: 100px;
        transform: translateY(-50%);
      }
      &:before {
        width: 62px;
        height: 24px;
        background: linear-gradient(180deg, #eee 0%, #f2f2f2 100%);
      }
      &:after {
        width: 32px;
        height: 32px;
        background: $color_main;
        box-shadow: 0 1.25rem 1.875rem rgba(128, 203, 64, 0.19), 0 0.5rem 0.75rem rgba(128, 203, 64, 0.37);
        transition: all 300ms ease;
      }
    }
  }
  @media (max-width: 980px) {
    &--inner {
      &--wrap {
        flex-direction: column;
      }
    }
  }
  @media (max-width: 767px) {
    &--item {
      flex: 1 1 auto;
    }
    &--form {
      &--wrap {
        flex-direction: column;
      }
    }
  }
  @media (max-width: 560px) {
    &--wrap {
      flex-direction: column;
    }
    &--item {
      margin-top: 30px;
      &:first-child {
        margin-top: 0;
      }
    }
    &--form {
      &--wrap {
        flex-direction: row;
      }
    }
  }
  @media (max-width: 479px) {
    &--form {
      &--wrap {
        flex-direction: column;
      }
    }
  }
}
.checkbox {
  &--label {
    position: relative;
    display: flex;
    min-height: 15px;
    padding-left: 30px;
    line-height: 1;
    background: none;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      top: -2px;
      left: 0;
      width: 18px;
      height: 18px;
      border: 0.063rem solid #e0e0e0;
      background: #fff;
      transition: all 300ms ease;
    }
    &--1 {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &--link {
    margin-left: 5px;
  }
  &--svg {
    position: absolute;
    top: -1px;
    left: 1px;
    width: 16px;
    height: 16px;
    fill: none;
    stroke: $color-main;
    stroke-width: 1.5;
    stroke-dasharray: 22;
    stroke-dashoffset: 66;
    transition: all 300ms ease;
    transform: translate3d(0, 0, 0);
  }
  &--show-basic-info {
    overflow: hidden;
    max-height: 0;
    transition: all 300ms ease;
  }
  &--input:checked {
    + .checkbox--label {
      &:before {
        border-color: $color-main;
      }
      .checkbox--svg {
        stroke-dashoffset: 42;
      }
    }
  }
  &--input--1:checked {
    ~ .checkbox--show-basic-info {
      max-height: 100%;
    }
    ~ .checkbox--label {
      &:before {
        border-color: $color-main;
      }
      .checkbox--svg {
        stroke-dashoffset: 42;
      }
    }
  }
  @media (max-width: 479px) {
    &--label {
      display: block;
    }
  }
}

.page-footer {
  margin: 40px 0;
  &--contact {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 273px;
    padding: 20px 0;
    text-align: center;
    background: #f8f8f8;
    &--text {
      display: flex;
      flex-direction: column;
      padding: 49px 0 0;
    }
  }
  &--main {
    background: #3a3838;
    &--text {
      padding: 40px 0;
      text-align: center;
    }
    &--info {
      padding: 40px 0;
      border-top: solid 1px #4e4c4c;
      &--wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: -10px;
      }
      &--item {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: 10px;
        &:first-child {
          justify-content: space-between;
        }
      }
    }
    h3 {
      padding: 0 0 25px;
    }
    h4 {
      padding: 0 0 25px;
    }
    p {
      color: #f8f8f8;
      line-height: 1.25;
    }
    a {
      color: #ebebeb;
    }
    ul {
      list-style: none;
    }
  }
  &--social {
    display: flex;
    align-items: center;
    margin: -5px;
    &--link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin: 5px;
      border-radius: 30px;
      background: #fff;
    }
  }
  .c-5 {
    color: #8d8d8d;
  }
  .t {
    font-weight: 700;
    &-1 {
      font-size: 0.875rem;
    }
    &-2 {
      font-size: 1rem;
    }
    &-3 {
      font-size: 1.25rem;
    }
    &-4 {
      font-size: 1.875rem;
    }
    &-5 {
      font-size: 2.813rem;
    }
    &-6 {
      font-size: 0.688rem;
    }
    &-7 {
      font-size: 0.75rem;
    }
    &-8 {
      font-size: 1.125rem;
    }
    &-9 {
      font-size: 0.938rem;
    }
    &-10 {
      font-size: 1.375rem;
    }
    &-11 {
      font-size: 2.5rem;
    }
  }
  .fw-400 {
    font-weight: 400;
  }
  .fw-500 {
    font-weight: 500;
  }
  .fw-700 {
    font-weight: 700;
  }
  @media (max-width: 767px) {
    &--main {
      &--info {
        &--item {
          flex: 1 1 50%;
        }
      }
    }
  }
  @media (max-width: 479px) {
    &--main {
      &--info {
        &--wrap {
          flex-direction: column;
        }
      }
    }
  }
}

.error-404 {
  display: flex;
  flex: 1 1 auto;
  height: calc(100vh - 300px);
  &--wrapper {
    padding: 24px 0;
  }
  &--img {
    display: block;
    margin: auto;
  }
  &--text {
    max-width: 600px;
    margin: 15px auto 0;
    color: #17140a;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
  }
  .container {
    margin: auto;
  }
}

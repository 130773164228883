.custom-select {
  position: relative;
  display: inline-block;
  max-width: 100%;
  color: $color_black;
  font-size: 14px;
  line-height: 1.5;
  outline: 0;
  margin-left: auto;
  &--box {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    background: $color_white;
    display: inline-flex;
    align-items: center;
    border-radius: 2px;
    &--50 {
      max-width: 50%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: auto;
    }
    &--grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
  }
  &--label {
    color: #787878;
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    padding-left: 14px;
  }
  select {
    appearance: none;
    position: fixed;
    bottom: 100%;
    right: 100%;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
  .icon {
    position: absolute;
    content: "";
    top: 50%;
    right: 15px;
    color: $color_black;
    transform: translate(0, -50%) scale(1, 1) rotate(90deg);
    transition: all 300ms ease;
    pointer-events: none;
    z-index: 200;
  }
  &.select-arrow-active {
    .icon {
      transform: translate(0, -50%) scale(-1, -1) rotate(90deg);
    }
  }
  @media (max-width: 767px) {
    &--box {
      max-width: 320px;
      width: 100%;
    }
  }
}
.select-selected {
  position: relative;
  z-index: 199;
  padding: 8px 35px 8px 16px;
  min-height: 40px;
  display: inline-flex;
  align-items: center;
  color: $color_black;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  user-select: none;
  transition: all 300ms ease;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
  }
}
.select-items {
  background: $color_white;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 300;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  animation: showDown 100ms;
  div {
    color: $color_secondary;
    background: $color_white;
    padding: 10px 16px;
    transition: all 300ms ease;
    cursor: pointer;
    &:hover {
      color: $color_white;
      background: $color_secondary;
    }
  }
}
.select-hide {
  display: none;
}
.same-as-selected {
  //background: #fff;
}

// hlavní kontejner
.container {
  max-width: $mqlg + $layout_gap*2;
  margin: 0 auto;
  padding: 0 $layout_gap + 5px;
  @media (min-width: $mqsm) {
    padding: 0 $layout_gap;
  }
}
// řádek mřížky
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 (-$layout_gap);
  // mezera mezi boxy
  & > .col {
    padding: $layout_gap;
  }
}
// fix pro max-width tabulku
.row--block {
  @media (max-width: $mqsm) {
    display: block;
  }
}
// řádek se stejně vysokými bloky
.row--align {
  & > .col {
    flex-direction: row;
  }
}
// mezery mezi bloky
.row--space {
  justify-content: space-between;
}
// centrování
.row--center {
  align-items: center;
}
// řádek bez mezer
.row--nogap {
  margin: 0;
  & > .col {
    padding: 0;
  }
}
// blok mřížky
.col {
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
}
// dva vedle sebe
.col--2 {
  @media (min-width: $mqxs) {
    flex-basis: auto;
    width: 50%;
  }
}
// tři vedle sebe
.col--3 {
  @media (min-width: $mqxs) {
    flex-basis: auto;
    width: 50%;
  }
  @media (min-width: $mqmd) {
    width: 33.33333333%;
  }
}
// čtyři vedle sebe
.col--4 {
  @media (min-width: $mqxs) {
    flex-basis: auto;
    width: 50%;
  }
  @media (min-width: $mqmd) {
    width: 25%;
  }
}
// pět vedle sebe
.col--5 {
  @media (min-width: $mqxs) {
    flex-basis: auto;
    width: 50%;
  }
  @media (min-width: $mqmd) {
    width: 20%;
  }
}
// šest vedle sebe
.col--6 {
  @media (min-width: $mqxs) {
    flex-basis: auto;
    width: 50%;
  }
  @media (min-width: $mqmd) {
    width: 16.66666666666667%;
  }
}
// buňka se neroztahuje
.col--precise {
  flex-grow: 0;
}
// zarovnání na pravou stranu
.col--end {
  align-items: flex-end;
}
// zarovnání na levou stranu
.col--start {
  align-items: flex-start;
}
// zarovnání na střed
.col--center {
  align-items: center;
}
// změna osy
.col--row {
  flex-direction: row;
}

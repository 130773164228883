.advantages {
  margin: 50px 0;
  &--wrapper {
  }
  &--grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 10px;
  }
  &--column {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 34px;
    text-align: center;
    text-decoration: none;
    background: $color_white;
    box-shadow: 0 2px 45px rgba(0, 0, 0, 0.1);
    &:hover {
      .advantages {
        &--button {
          color: $color_white;
          border-color: transparent;
          background: linear-gradient(77deg, #fa1254 0%, #e90966 100%);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        }
      }
    }
  }
  &--img {
    height: 50px;
    margin: 0 auto 15px;
  }
  &--headline {
    margin: 0;
    color: #2d3b42;
    font-size: 18px;
    font-weight: 700;
  }
  &--text {
    margin: 15px 0 20px;
    color: #636d79;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.75;
  }
  &--button {
    display: inline-block;
    margin-top: auto;
    padding: 10px 19px 8px 20px;
    color: #2d3b42;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.75;
    border: solid 1px #ccc;
    border-radius: 2px;
    background: #fff;
    transition: all 300ms ease;
  }
  @media (max-width: 767px) {
    &--grid {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
  }
}

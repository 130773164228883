.map-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fbf7f4 url(../img/placeholder.svg) center center no-repeat;
  #js-map {
    pointer-events: none;
  }
  &.is-active {
    #js-map {
      pointer-events: auto;
    }
  }
}
.icon-property {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 100vmax;
  background: #fefcfa;
  box-shadow: $shadow-1;
  * {
    width: 20px;
    height: 20px;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background: rgba(35, 34, 32, 0.9);
  opacity: 0;
  transition: all 300ms ease;
  transform: translate(0, -10px);
  pointer-events: none;
  &.js-show {
    opacity: 1;
    transform: translate(0, 0);
    pointer-events: auto;
  }
  &--scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
  }
  &--wrapper {
    position: relative;
    z-index: 1002;
    width: 100%;
    max-width: 800px;
    margin: 10vh auto 100px;
  }
  &--box {
    padding: 80px 64px;
    background: $color_white;
  }
  &--close {
    &--full {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1001;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      border: none;
      outline: none;
      background: none;
      cursor: default;
    }
    &--button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 15px;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      transition: all 300ms ease;
      &:hover {
        transform: scale(1.1);
      }
      &--icon {
        width: 25px;
      }
    }
  }
  &--hide {
    overflow: hidden;
    max-height: 0;
    transition: all 300ms ease;
    &--row {
      margin-top: 30px;
    }
    &--button {
      display: inline-block;
      margin: -15px 0 5px;
      padding: 15px 10px;
      color: #17140a;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.43;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      > * {
        display: inline-block;
        vertical-align: middle;
      }
      &--more {
      }
      &--less {
        display: none;
      }
      &.js-active {
        .modal--hide--button {
          &--more {
            display: none;
          }
          &--less {
            display: inline;
          }
        }
      }
    }
  }
  &--footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    &--button {
      justify-content: space-between;
      min-width: 260px;
      min-height: 64px;
      margin: 20px 0 0;
      font-size: 18px;
      font-weight: 700;
    }
  }
  @media (max-width: 767px) {
    &--box {
      padding: 60px 10px;
    }
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 13px 17px;
  color: #fff;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  border: none;
  border-radius: 0;
  outline: none;
  background: linear-gradient(82deg, #fa1254 0%, #e90966 100%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  transition: all 300ms ease;
  &:hover:not(.button--simple) {
    color: $color_white;
    text-decoration: none;
    border-color: $color_main_dark;
    background: $color_main_dark;
  }
  &--secondary {
    padding: 10px 20px;
    border-color: $color_secondary;
    //border-radius: 2px;
    background: $color_secondary;
    //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.38);
    &:hover {
      border-color: $color_secondary-dark;
      background: $color_secondary-dark;
    }
  }
  &--simple {
    min-height: 0;
    margin: 0 -26px;
    color: $color_main;
    font-weight: 500;
    text-decoration: underline;
    border: none;
    background: none;
    box-shadow: none;
    &:hover {
      color: $color_main_dark;
    }
  }
  &--icon {
    margin-right: 10px;
  }
  &--radius {
    //border-radius: 2px;
    //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.38);
  }
  &--wrapper {
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-gap: 16px;
  }
  @media (max-width: 1023px) {
    min-height: 38px;
    padding: 5px 10px;
    &--simple {
      min-height: 0;
      margin: -10px -26px 0;
      padding: 10px 26px;
    }
  }
}

.detail-info {
  margin: 45px 0 80px;
  &--wrapper {
  }
  &--grid {
    display: grid;
    grid-template-columns: 500px 450px;
    grid-gap: 40px;
    justify-content: space-between;
  }
  &--column {
    &:nth-of-type(2) {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
    }
    &--main-info {
      margin-bottom: 48px;
    }
  }
  &--box {
    &--headline {
      font-size: 30px;
    }
  }
  &--hidden {
    position: relative;
    overflow: hidden;
    max-height: 980px;
    transition: all 300ms ease;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 30px;
      background-image: linear-gradient(rgba($color_white, 0), $color_white);
      transition: all 300ms ease;
      pointer-events: none;
    }
    &.js-active {
      &:before {
        opacity: 0;
      }
    }
  }
  &--show {
    &--button {
      margin: 0 -20px;
      padding: 20px;
      color: $color_main;
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      &--text {
        &--more {
        }
        &--less {
          display: none;
        }
      }
      &--icon {
        margin-left: 10px;
        transition: all 300ms ease;
      }
      &.js-active {
        .detail-info--show--button {
          &--text {
            &--more {
              display: none;
            }
            &--less {
              display: inline;
            }
          }
          &--icon {
            transform: rotate(90deg) scale(-1, 1);
          }
        }
      }
    }
  }
  h1 {
    text-shadow: none;
  }
  @media (max-width: 1199px) {
    &--grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 1023px) {
    &--grid {
      grid-template-columns: 1fr 380px;
      grid-gap: 30px;
    }
    &--box {
    }
    &--column {
    }
  }
  @media (max-width: 767px) {
    &--grid {
      display: block;
    }
    &--column {
      &:nth-of-type(2) {
        margin: 0;
      }
    }
  }
}

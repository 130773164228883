.portfolio-header {
  margin: 30px 0;
  &--wrapper {
    padding: 12px 0;
    border-top: 1px solid rgba(#18274d, 0.12);
    border-bottom: 1px solid rgba(#18274d, 0.12);
  }
  &--link {
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 5px 12px;
    color: $color_secondary;
    font-size: 14px;
    text-decoration: none;
    border: none;
    background: none;
    &:hover {
      color: $color_main;
      text-decoration: none;
    }
    &--icon {
      width: 25px;
      margin-left: 8px;
      fill: currentColor;
      transition: all 300ms ease;
    }
  }
  &--switch {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 7px;
    color: #232220;
    font-size: 14px;
    font-weight: 400;
    border: none;
    outline: none;
    background: none;
    transition: all 0.3s ease;
    &:hover {
    }
    .icon {
      margin-right: 10px;
    }
    &.js-active {
      color: #c60a40;
      border-radius: 2px;
      background: rgba(#fa1254, 0.12);
    }
  }
  &--text {
    margin-left: 20px;
    color: $color_black;
    font-size: 14px;
    font-weight: 500;
  }
  &--grid {
    display: flex;
    justify-content: space-between;
  }
  &--column {
    display: flex;
    align-items: center;
  }
  &--item {
    margin-right: 16px;
    color: $color_black;
    font-size: 14px;
    font-weight: 500;
    &--inner {
      margin-left: 42px;
    }
  }
  .select-selected {
    font-weight: 700;
  }
  @media (max-width: 1023px) {
    margin: 10px 0 30px;
    &--wrapper {
      justify-content: center;
    }
    &--grid {
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
  @media (max-width: 767px) {
    &--item,
    &--switch,
    &--link {
      display: none;
    }
  }
}

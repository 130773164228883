.typo-references {
  display: grid;
  align-items: center;

  gap: 1rem;
  @media (min-width: 48rem) {
    grid-template-columns: 270px 1fr 140px;
  }
  p {
    margin: 0;
  }
}
.typo-references--thumbnail {
  font-size: 0.75rem;
  text-align: center;
  img {
    border-radius: 100px;
  }
}

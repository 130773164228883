
.icon--icon-arrow-right {
  width: 5.937px;

  &:before {
    padding-top: (8.822 / 5.937) * 100%;
  }
}

.icon--icon-arrow-right-color {
  width: 5.937px;

  &:before {
    padding-top: (8.822 / 5.937) * 100%;
  }
}

.icon--icon-arrow-right-full {
  width: 19px;

  &:before {
    padding-top: (17 / 19) * 100%;
  }
}

.icon--icon-arrow-slider {
  width: 18px;

  &:before {
    padding-top: (29 / 18) * 100%;
  }
}

.icon--icon-buy {
  width: 45px;

  &:before {
    padding-top: (39 / 45) * 100%;
  }
}

.icon--icon-check {
  width: 18px;

  &:before {
    padding-top: (18 / 18) * 100%;
  }
}

.icon--icon-check-color {
  width: 14px;

  &:before {
    padding-top: (11 / 14) * 100%;
  }
}

.icon--icon-close {
  width: 12px;

  &:before {
    padding-top: (12 / 12) * 100%;
  }
}

.icon--icon-commercial {
  width: 43px;

  &:before {
    padding-top: (43 / 43) * 100%;
  }
}

.icon--icon-copy {
  width: 20px;

  &:before {
    padding-top: (20 / 20) * 100%;
  }
}

.icon--icon-facebook {
  width: 20px;

  &:before {
    padding-top: (20 / 20) * 100%;
  }
}

.icon--icon-flat {
  width: 383.25px;

  &:before {
    padding-top: (383.1 / 383.25) * 100%;
  }
}

.icon--icon-gear {
  width: 16px;

  &:before {
    padding-top: (16 / 16) * 100%;
  }
}

.icon--icon-globe {
  width: 33px;

  &:before {
    padding-top: (39 / 33) * 100%;
  }
}

.icon--icon-grounds {
  width: 34px;

  &:before {
    padding-top: (39 / 34) * 100%;
  }
}

.icon--icon-house {
  width: 42px;

  &:before {
    padding-top: (40.746 / 42) * 100%;
  }
}

.icon--icon-instagram {
  width: 20px;

  &:before {
    padding-top: (20 / 20) * 100%;
  }
}

.icon--icon-map-point-fill {
  width: 41px;

  &:before {
    padding-top: (55 / 41) * 100%;
  }
}

.icon--icon-menu {
  width: 185.208px;

  &:before {
    padding-top: (185.208 / 185.208) * 100%;
  }
}

.icon--icon-other {
  width: 43px;

  &:before {
    padding-top: (41 / 43) * 100%;
  }
}

.icon--icon-phone {
  width: 16px;

  &:before {
    padding-top: (16 / 16) * 100%;
  }
}

.icon--icon-recreational {
  width: 42px;

  &:before {
    padding-top: (42.231 / 42) * 100%;
  }
}

.icon--icon-reference {
  width: 44px;

  &:before {
    padding-top: (40 / 44) * 100%;
  }
}

.icon--icon-sell {
  width: 43px;

  &:before {
    padding-top: (42 / 43) * 100%;
  }
}

.icon--icon-settings {
  width: 18px;

  &:before {
    padding-top: (18 / 18) * 100%;
  }
}

.icon--icon-share {
  width: 512px;

  &:before {
    padding-top: (512 / 512) * 100%;
  }
}

.icon--icon-share-color {
  width: 18px;

  &:before {
    padding-top: (20 / 18) * 100%;
  }
}

.icon--icon-share-email {
  width: 24px;

  &:before {
    padding-top: (24 / 24) * 100%;
  }
}

.icon--icon-share-facebook {
  width: 24px;

  &:before {
    padding-top: (24 / 24) * 100%;
  }
}

.icon--icon-share-instagram {
  width: 24px;

  &:before {
    padding-top: (24 / 24) * 100%;
  }
}

.icon--icon-share-x {
  width: 24px;

  &:before {
    padding-top: (24 / 24) * 100%;
  }
}

.icon--icon-switch-listing {
  width: 18.28px;

  &:before {
    padding-top: (18.2 / 18.28) * 100%;
  }
}

.icon--icon-switch-map {
  width: 16.62px;

  &:before {
    padding-top: (21.78 / 16.62) * 100%;
  }
}

.icon--icon-x {
  width: 20px;

  &:before {
    padding-top: (20 / 20) * 100%;
  }
}

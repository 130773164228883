.radio {
  &--wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 24px;
    color: $color_white;
  }
  &--color {
    &--secondary {
      color: $color_secondary;
    }
    &--black {
      color: $color_black;
    }
  }
  &--label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 0 0 0 32px;
    color: currentColor;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 24px;
      height: 24px;
      border-radius: 2px;
      background: $color_white;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
      transform: translate(0, -50%);
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 4px;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      background: $color_secondary;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
      transition: all 300ms ease;
      transform: translate(0, -50%) scale(0);
    }
    a {
      margin-left: 3px;
    }
  }
  &--input:checked {
    + .radio--label:after {
      transform: translate(0, -50%) scale(1);
    }
  }
  @media (max-width: 767px) {
    &--wrapper {
      grid-template-columns: auto auto;
    }
  }
}

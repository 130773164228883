.gallery {
  margin: 80px 0;
  &--wrapper {
    margin: auto;
  }
  &--grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    &--detail {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      .gallery {
        &--item {
          padding-top: 100%;
        }
        &--more-images {
          font-size: 18px;
          &--button {
            min-height: 0;
            margin: -10px;
            padding: 10px;
            color: $color_main;
            font-size: 14px;
            font-weight: 500;
            text-align: right;
            text-decoration: underline;
            border: none;
            background: none;

            grid-column: span 2;
          }
        }
      }
    }
  }
  &--item {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-top: 75%;
    background: white;
  }
  &--img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
  }
  &--more-images {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 36px;
    font-weight: 400;
    line-height: 0.83;
    text-transform: uppercase;
    border: none;
    outline: 0;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    &--button {
      margin-top: 15px;
      letter-spacing: inherit;
    }
  }
  .container {
    max-width: 700px;
  }
  @media (max-width: 767px) {
    &--grid {
      grid-gap: 12px;
      &--detail {
        position: relative;
        .gallery {
          &--item {
            &:nth-of-type(3),
            &:nth-of-type(4) {
              display: none;
            }
          }
          &--more-images {
            &--button {
              position: absolute;
              top: 0;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: calc(50% - 6px);
              height: 100%;
              margin: 0;
              color: #fff;
              line-height: normal;
              text-align: center;
              background: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
    }
    &--more-images {
      font-size: 24px;
    }
  }
}
.fancybox-inner {
  .gallery {
    &--item {
      position: static;
      display: block;
      overflow: visible;
      width: 100%;
      height: auto;
      padding: 0;
      background: none;
    }
    &--img {
      position: static;
      width: 100%;
      height: auto;
    }
  }
}

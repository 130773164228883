.breadcrumbs {
  position: relative;
  background: $color_main;
  &--wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &--nopadding {
    padding-left: 0;
  }
  &--back {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    min-width: 0;
    margin: 10px 0 0 10px;

    gap: 8px;
    &--icon {
      transform: rotate(180deg);
    }
  }
  .list-breadcrumb {
    font-size: 14px;
  }
  &--detail-container {
    padding: 0 10px;
    .list-breadcrumb {
      position: relative;
    }
    .list-breadcrumb:before {
      content: '';
      position: absolute;
      right: -15px;
      bottom: 0;
      z-index: 1;
      width: 30px;
      height: 100%;
      color: #fff;
      background: linear-gradient(to right, rgba(24, 39, 77, 0), $color_main);
    }
  }
}
.list-breadcrumb {
  display: flex;
  overflow: hidden;
  align-items: center;
  height: 48px;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  text-overflow: ellipsis;

  gap: 40px;
  &__item {
    position: relative;
    display: inline-block;
    color: $color_white;
    vertical-align: middle;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      width: 7px;
      height: 10px;
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDcgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjAzODA5IDEuNDYyMDFMMS41MjAwOCAxLjAxMDAxTDUuNTEwMDkgNC43MzYwMUwxLjUyMDA4IDguNDYyMDFMMS4wMzgwOSA4LjAxMDAxTDQuNTQzMDkgNC43MzMwMUwxLjAzODA5IDEuNDYyMDFaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utb3BhY2l0eT0iMC4zOCIvPgo8L3N2Zz4K') no-repeat;
      transform: translate(17px, -50%);
    }
    &:last-of-type {
      overflow: hidden;
      margin: 0;
      padding: 0;
      &:before {
        display: none;
      }
    }
    &__in {
      color: $color_white;
      text-decoration: none;
    }
  }
}

.page-nav {
  display: flex;
  margin: 0 -15px;
  padding: 0;
  list-style: none;
  color: $color_black;
  &--li {
    position: relative;
    padding: 0;
    border-radius: 2px;
    &.js-active {
      color: $color_main;
    }
    &:hover {
      color: $color_main;
      background: rgba(#fa1254, 0.12);
      .page-nav {
        &--sub {
          opacity: 1;
          transform: translate(0, 0);
          pointer-events: auto;
        }
      }
    }
  }
  &--link {
    display: block;
    padding: 11px 15px;
    color: currentColor;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
  }
  &--sub {
    position: absolute;
    top: 100%;
    left: 15px;
    flex-direction: column;
    width: 230px;
    padding: 15px 0;
    color: #252529;
    font-size: 14px;
    font-weight: 400;
    border-radius: 2px;
    background: #f7f7f7;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: all 300ms ease;
    transform: translate(0, -10px);
    pointer-events: none;
    .page-nav {
      &--li {
        &:hover {
          color: #fa1254;
          background: none;
        }
      }
      &--link {
        padding: 8px 30px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @media (max-width: 1199px) {
    margin: 0 -8px;
    &--link {
      padding: 16px 8px;
    }
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    margin: 0;
    &--link {
      padding: 16px;
    }
    &--sub {
      position: static;
      width: auto;
      padding: 0;
      color: $color_black;
      background: none;
      opacity: 1;
      transform: none;
    }
  }
}

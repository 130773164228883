.author-info {
  margin: 0 0 40px;
  padding: 46px 55px;
  background: #636d79;
  &--inner {
    display: grid;
    align-items: center;
    grid-template-columns: 95px 1fr;
    grid-gap: 30px;
  }
  &--img {
    //width: 95px;
    //height: 95px;
    object-fit: cover;
    //border-radius: 1000px;
  }
  &--text {
  }
  &--name {
    margin: 0 0 5px;
    color: $color_white;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
  }
  &--tel {
    display: block;
    color: $color_white;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.33;
    text-decoration: none;
  }
  &--email {
    display: block;
    color: $color_white;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.33;
    text-decoration: underline;
  }
  h1 {
    margin: 0 0 24px;
    color: $color_white;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.17;
  }
  @media (max-width: 1023px) {
    padding: 16px;
    &--inner {
      grid-gap: 12px;
    }
    h1 {
      margin: 0 0 12px;
    }
  }
  @media (max-width: 767px) {
    grid-template-columns: 100px 1fr;
    grid-gap: 16px;
    &--img {
      height: 100px;
    }
  }
}

.typo {
  margin: 55px 0;
  &--wrapper {
    > img {
      float: right;
      max-width: 50%;
      margin: 10px 0 10px 65px;
    }
  }
  .container {
    //max-width: 900px;
  }
}

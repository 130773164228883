.reference-text {
  margin: 50px 0 100px;
  &--wrapper {
  }
  &--column {
    display: flex !important;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    margin: 10px;
    padding: 34px;
    text-decoration: none;
    background: rgba(#e4e4e4, 0.24);
    &:hover {
    }
  }
  &--text {
    margin-bottom: auto;
    color: #636d79;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.75;
  }
  &--name {
    display: block;
    margin-top: 20px;
    color: #2d3b42;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 0 10px;
    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  .slick-list {
    margin: 0 -10px;
  }
  @media (max-width: 479px) {
    .slick-slide {
      margin: 0;
    }
    .slick-list {
      margin: 0;
    }
  }
}

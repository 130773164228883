.portfolio {
  margin: 0 0 60px;
  &--wrapper {
  }
  &--header {
    margin: 60px auto 30px;
  }
  &--grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 24px;
  }
  &--column {
    display: flex;
    flex-direction: column;
    padding: 16px;
    text-decoration: none;
    border-radius: 2px;
    background: $color_white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    &.js-reserved {
      .portfolio {
        &--img {
        }
        &--price {
          color: #2d3b42;
        }
        &--reserved {
          display: flex;
        }
      }
    }
    &:hover {
      color: $color_secondary;
      .portfolio {
        &--hidden {
          opacity: 1;
          transform: none;
          pointer-events: auto;
        }
        &--text {
          &--main {
            text-decoration: underline;
          }
        }
        &--reserved {
          opacity: 0;
        }
      }
    }
  }
  &--row {
    .portfolio {
      &--column {
        margin-top: 20px;
        padding: 35px;
        border-radius: 0;
        background: $color_white;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.22);
        &:hover {
          .portfolio {
            &--text {
              &--main {
                text-decoration: none;
              }
            }
            &--reserved {
              opacity: 1;
            }
          }
        }
      }
      &--headline {
        margin: 0 0 5px;
        color: #2d3b42;
        font-size: 24px;
        font-weight: 700;
      }
      &--price {
        flex: 0 0 auto;
        margin: 0;
        color: #f20e5c;
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
      }
      &--text {
        text-decoration: none;
        &:hover {
          .portfolio {
            &--headline {
              text-decoration: underline;
            }
          }
        }
        &--main {
          position: relative;
          margin: 0;
          padding-left: 27px;
          color: #2d3b42;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          &:before {
            content: '';
            position: absolute;
            top: 4px;
            left: 10px;
            width: 7px;
            height: 7px;
            border-radius: 100px;
            background: #ebebeb;
          }
        }
        &--footer {
          margin: 20px 0 0;
          color: #818b8c;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.46;
        }
      }
      &--img {
        width: 100%;
        height: 100%;
        &--box {
          display: flex;
          align-items: center;
          height: auto;
          box-shadow: none;
          &.slick-initialized {
            overflow: visible;
          }
          &--inner {
            position: relative;
            flex: 0 0 326px;
            width: 326px !important;
            height: 187px;
          }
        }
      }
    }
    .slick-slide {
      margin: 0 6px;
    }
    .slick-list {
      margin: 0 -6px;
    }
    .slick-prev {
      display: none !important;
    }
    .slick-next {
      top: 50%;
      right: -24px;
      left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 47px;
      height: 47px;
      border: solid 7px #fff;
      border-radius: 100px;
      background: #fa1254;
      transform: translate(0, -50%) rotate(180deg);
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 33px;
        height: 33px;
        border-radius: 100px;
        box-shadow: 0 2px 2px rgba(198, 10, 64, 0.27);
      }
      .icon {
        position: relative;
        top: auto;
        left: auto;
        width: 7px;
        transform: none;
      }
    }
  }
  &--inner {
    display: flex;
  }
  &--img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &--box {
      position: relative;
      overflow: hidden;
      height: 203px;
      border-radius: 2px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    }
  }
  &--hidden {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow: auto;
    text-align: center;
    background: $color_main;
    opacity: 0;
    transition: all 300ms ease;
    transform: scale(0);
    pointer-events: none;
    &--text {
      width: 100%;
      margin: auto;
      padding: 23px;
      color: $color_white;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.57;
      opacity: 0.9;
      &:last-child {
        margin: auto;
      }
    }
    .simplebar-content-wrapper,
    .simplebar-content {
      display: flex;
      width: 100%;
    }
  }
  &--reserved {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: $color_white;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 0 30px $color_black;
    transition: all 300ms ease;
  }
  &--text {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin: 16px 0 0;
    &--main {
      margin: 10px 0 4px;
      color: #2d3b42;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.23;
    }
    &--footer {
      margin: auto 0 0;
      color: #818b8c;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.46;
    }
  }
  &--price {
    margin: 0;
    color: #2d3b42;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
  }
  .map {
    height: 900px;
    max-height: calc(100vh - 96px - 50px);
  }
  @media (max-width: 1023px) {
    &--headline {
      line-height: normal;
    }
  }
  @media (max-width: 767px) {
    &--grid {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
    &--row {
      .portfolio {
        &--column {
          padding: 20px;
        }
        &--headline {
          font-size: 22px;
        }
        &--inner {
          display: block;
        }
        &--text {
          &--main {
            margin-top: 8px;
            padding-left: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.brokers {
  margin: 30px 0 80px;
  &--wrapper {
  }
  &--grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 30px;
  }
  &--column {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 30px;
    padding: 30px;
    border: 1px solid #ededed;
  }
  &--img {
    width: 100%;
    min-width: 150px;
    max-width: 200px;
    min-height: 210px;
    object-fit: cover;
  }
  &--text {
  }
  &--name {
    overflow: hidden;
    margin: 0 0 15px;
    font-size: 20px;
    line-height: 1.2;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  dl {
    margin: 0 0 15px;
    &:last-child {
      margin: 0;
    }
    a {
      display: block;
    }
  }
  dt {
    color: #b8b8b8;
    font-size: 14px;
    line-height: normal;
  }
  dd {
    overflow: hidden;
    margin: 0;
    color: $color_black;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .button {
    display: inline-block;
    min-height: 0;
    margin-top: auto;
    padding: 13px 17px;
    color: #fff;
    font-size: 0.875rem;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    border: none;
    border-radius: 0;
    background: #f81157;
    box-shadow: none;
    transition: all 300ms ease;
    + .button {
      margin-top: 15px;
    }
  }
  @media (max-width: 767px) {
    &--grid {
      grid-template-columns: 1fr;
    }
    &--column {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    &--img {
      width: 100%;
      max-width: 300px;
      max-height: calc(100vh - 100px);
    }
  }
}
.broker__box {
  display: grid;
  margin-bottom: 32px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  img {
    display: block;
    max-height: 200px;
  }
  dl {
    display: grid;
    grid-template-columns: auto 1fr;

    grid-template-rows: repeat(auto-fill, 16px);
    gap: 12px;
  }
  dd {
    font-weight: 500;
  }
  dt {
    color: #525252;
  }
}
.broker__box--rk {
  > div:first-child {
    padding: 32px;
  }
  img {
    margin: 0 auto 24px;
  }
  @media (min-width: $mqsm) {
    grid-template-columns: 1fr 1.5fr;
  }
}
.broker__box--broker {
  align-items: start;
  padding: 32px;
  > div:last-child {
    display: grid;

    gap: 16px;
    @media (min-width: $mqsm) {
      grid-template-columns: repeat(2, 1fr);

      gap: 24px;
    }
  }
  @media (min-width: $mqsm) {
    grid-template-columns: 1fr 3fr;
  }
}

// box sizing
html {
  scroll-behavior: smooth;
  position: relative;
  box-sizing: border-box;
  min-height: 100%;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
img {
  box-sizing: content-box;
}
// nastavení velikosti písma
:root {
  font-size: 100%;
}
// základní definice
body {
  position: relative;
  height: 100%;
  min-height: 100%;
  margin: 0;
  color: $color_black;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1;
  background: $color_white;
  &.js-freeze {
    overflow: hidden;
  }
}
.body-wrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  @media (pointer: none), (pointer: coarse) {
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: fill-available;
  }
}
.main {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}
.page-main {
  margin-top: 80px;
  @media (max-width: 767px) {
    margin-top: 70px;
  }
}
// základní nastavení respo
@-ms-viewport {
  width: device-width;
}
img,
picture {
  -ms-interpolation-mode: nearest-neighbor;
  transform: translateZ(0);
  will-change: transform;

  backface-visibility: hidden;
  image-rendering: auto;
}
img,
svg {
  display: inline-block;
  max-width: 100%;
  height: auto;
  border: none;
}
iframe {
  max-width: 100%;
}
label {
  user-select: none;
}
textarea {
  max-width: 100%;
}
hr {
  width: 100%;
  height: 1px;
  margin: 50px 0;
  border: none;
  background: #979797;
  opacity: 0.21;
}
.js-hide {
  display: none !important;
}
.marker-cluster {
  background-color: rgba(255, 255, 255, 1);
}
.marker-cluster div {
  color: white;
  background-color: rgba(128, 203, 64, 1);
}

.blog {
  margin: 90px 0 120px;
  h1 {
    margin: 0 0 35px;
  }
  &--wrapper {
    overflow: hidden;
  }
  &--grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 19px;
  }
  &--column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 34px;
    text-decoration: none;
    background: rgba(#e4e4e4, 0.24);
    &:hover {
      .blog {
        &--text {
          &--main {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &--header {
    position: relative;
    display: block;
    &--img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
  &--badge {
    position: absolute;
    top: 9px;
    left: 9px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 48px;
    height: 55px;
    color: $color_white;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    background: $color_main;
    &--text {
      display: block;
      font-size: 12px;
      font-weight: 500;
    }
  }
  &--text {
    overflow: hidden;
    height: 100px;
    margin-bottom: auto;
    color: #636d79;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.75;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(#f9f9f9, 0), #f9f9f9);
    }
    &--main {
      display: block;
      margin: 0 0 5px;
      color: #2d3b42;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
    }
    &--more {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 39px;
      margin: 25px 0 0;
      padding: 10px 19px 8px 20px;
      color: $color_white;
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      border-radius: 2px;
      background-image: linear-gradient(77deg, #fa1254 0%, #e90966 100%);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
  }
  &--date {
    display: block;
    margin: 2px 0 15px;
    color: #f81157;
    font-size: 14px;
    font-weight: 700;
  }
  &--more {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 40px;
    margin: 60px auto 50px;
    padding: 11px 12px;
    color: #2d3b42;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-decoration: none;
    border: 1px solid #e3e3e3;
    box-shadow: 0 1px 2px rgba(221, 221, 221, 0.31);
    transition: all 300ms ease;
    &:hover {
      color: $color_white;
      background-image: linear-gradient(77deg, #fa1254 0%, #e90966 100%);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 100vw;
      height: 1px;
      background: #e3e3e3;
      transform: translate(0, -50%);
    }
    &:before {
      left: 100%;
    }
    &:after {
      right: 100%;
    }
  }
  @media (max-width: 767px) {
    &--grid {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
  }
}

// zarovnání textu
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
// nezalamování
.nowrap {
  white-space: nowrap;
}
// floatování
.fleft {
  float: left;
}
.fright {
  float: right;
}
.cls {
  clear: both;
}
// stavy
.is-visible {
  display: block;
}
.is-open {
  display: block;
}
.is-hidden {
  display: none;
}
// micro clearfix ( http://nicolasgallagher.com/micro-clearfix-hack/ )
.cf:before,
.cf:after {
  content: ' ';
  display: table;
}
.cf:after {
  clear: both;
}
.cf {
  *zoom: 1;
}

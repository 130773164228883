.sub-header {
  position: relative;
  z-index: 1;
  background: #fff;
  &--wrapper {
  }
  h1 {
  }
  &--typo {
  }
  &--no-bg {
    + .main-carousel {
      .main-carousel--slider--item {
        height: 600px;
      }
    }
  }
}

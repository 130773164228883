.detail-slider {
  margin: 0 0 45px;
  &--wrapper {
  }
  &--inner {
    position: relative;
    &.js-reserved {
      .detail-slider {
        &--img {
        }
        &--reserved {
          display: block;
        }
      }
    }
  }
  &--item {
    position: relative;
    height: 495px;
  }
  &--reserved {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 10;
    display: none;
    width: 100%;
    padding: 10px;
    color: #fff;
    font-size: 28px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 0 25px rgba(0, 0, 0, 0.76);
    transform: translate(0, -50%);
    pointer-events: none;
  }
  &--link {
  }
  &--img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
  }
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    top: 50%;
    right: 12px;
    left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47px;
    height: 47px;
    border: solid 7px #fff;
    border-radius: 100px;
    background: #fa1254;
    transform: translate(0, -50%) rotate(180deg);
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 33px;
      height: 33px;
      border-radius: 100px;
      box-shadow: 0 2px 2px rgba(198, 10, 64, 0.27);
    }
    .icon {
      position: relative;
      top: auto;
      left: auto;
      width: 7px;
      transform: none;
    }
  }
  @media (max-width: 1023px) {
    margin: 0 0 15px;
    &--item {
      height: 250px;
    }
  }
  @media (max-width: 767px) {
  }
}
.detail-slider-variable-width {
  margin: 0 0 45px;
  .detail-slider {
    &--item {
      height: 350px;
    }
  }
  .slick-slide {
    position: relative;
    display: flex;
    height: 350px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: -1px;
      bottom: 0;
      z-index: 1;
      width: 2px;
      background: #fff;
    }
    img {
      width: auto;
    }
  }
  iframe {
    width: 621.5px;
  }
  @media (max-width: 767px) {
    iframe {
      width: 100%;
    }
  }
}

// barvy
// základní barvy
$color_white: #fff;
$color_black: #000; 
// šedá barva
$color_gray: #707070;
$color_gray_dark: #333;
$color_gray_light: #f0f0f0;
// hlavní barvy projektu
$color_main: #f81157;
$color_main_dark: darken($color_main, 10%);
$color_main_light: lighten($color_main, 50%);
// sekundární barvy projektu
$color_secondary: #252529;
$color_secondary_dark: darken($color_secondary, 10%);
$color_secondary_light: lighten($color_secondary, 50%);
// barvy pro chybové hlášky
$color_success: #40b840;
$color_info: #2d687f;
$color_danger: #c81917;

$shadow-1: 0 0.6px 1.8px rgba(0, 0, 0, 0.11), 0 3.2px 7.2px rgba(0, 0, 0, 0.13);
// lightbox
$sl-overlay-background: $color_main;
$sl-overlay-opacity: 0.9;
$sl-navigation-color: $color_white;
// zakulacené rohy
$radius: 4px;
$radius_button: 0;
// layout
$layout_gap: 10px;
// breakpointy
// základní převzaty z Bootstrap Gridu
$mqxs: 576px; // small
$mqsm: 768px; // medium
$mqmd: 992px; // large
$mqlg: 1072px; // extra large
// rozšířené
$mqxxs: 419px; // extra small
$mqxlg: 1380px; // max large
$mq_menu: 860px; // respo menu
$mq_modal: 700px; // modal okno
// z-index
$index_base: 1;
$index_page: 10;
$index_menu: 100;
$index_modal: 1000;
$fontPath: "../fonts";
$imgPath: "../img";
$fontFamily: "Montserrat";

$transition: all 300ms ease;

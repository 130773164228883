.language {
  position: relative;
  display: flex;
  align-items: center;
  height: 37px;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #e9e9e9;
  &--label {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    color: $color_black;
    cursor: pointer;
  }
  &--hide {
    position: absolute;
    top: 100%;
    left: 15px;
    display: none;
    width: calc(22px + 10px);
    background: #fff;
    box-shadow: 2px 2px 2px 0 #4c4f56;
  }
  &--link {
    display: block;
    padding: 5px;
    text-decoration: none;
  }
  &--img {
    width: 22px;
  }
  &--switch:checked {
    ~ .language {
      .language {
        &--hide {
          display: block;
        }
      }
    }
  }
  @media (max-width: 1199px) {
    margin-left: 10px;
    padding-left: 10px;
  }
  @media (max-width: 1023px) {
    &--hide {
      top: auto;
      bottom: 100%;
    }
  }
}

.tabs {
  margin: 24px 0;
}
.tabs__top {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -($layout_gap + 5px);
    width: 60px;
    height: 100%;
    background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
    @media (min-width: $mqlg) {
      right: -$layout_gap;
    }
  }
  .simplebar-content {
    display: flex;
  }
}
.tabs__menu {
  display: flex;
  overflow: auto;
  margin: 0 0 24px 0;
  padding-right: 20px;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #e1e8f2;
  }
}
.tabs__menu-item,
a.tabs__menu-item {
  position: relative;
  display: inline-block;
  padding: 16px 18px;
  color: rgba($color: #000, $alpha: 0.6);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  transition: $transition;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 2px;
    background: $color-main;
    opacity: 0;
    transition: $transition;
    transform: translate(-50%, 0);
  }
  &:hover,
  &:focus,
  &.is-open {
    color: #000;
  }
  &.is-open {
    &:after {
      opacity: 1;
    }
  }
}
.tabs__content {
}
.tabs__tab {
  display: none;
  &.is-open {
    display: block;
  }
}

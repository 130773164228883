.main-carousel {
  position: relative;
  z-index: 1;
  margin-top: -80px;
  &--item {
    position: relative;
  }
  &--bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &--content {
    position: relative;
    z-index: 4;
    width: 100%;
    max-width: 1020px;
    margin: auto;
    padding: 0 10px;
  }
  &--headline {
    &--1 {
      margin: 0;
      color: $color_white;
      font-size: 64px;
      font-weight: 700;
      line-height: 1.13;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
    }
    &--2 {
      margin: 15px 0;
      color: $color_white;
      font-size: 24px;
      font-weight: 600;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
  }
  &--slider {
    position: relative;
    z-index: 4;
    width: 100vw;
    &--item {
      position: relative;
      z-index: 2;
      display: flex !important;
      align-items: center;
      justify-content: center;
      height: 100vh;
      min-height: 200px;
      padding: 100px 0 243px;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
        background: rgba(0, 0, 0, 0.2);
      }
    }
    &--img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      object-fit: cover;
    }
  }
  &--breadcrumb {
    margin: 0;
    .main-carousel--slider--item {
      height: 600px;
    }
  }
  &--button-wrapper {
    display: flex;
    align-items: center;
  }
  &--arrow-wrapper {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  &--video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    video {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    ~ .main-carousel--item {
      .main-carousel--slider--img {
        display: none !important;
      }
    }
  }
  .slick-arrow {
    padding: 14px;
    .icon {
      width: 13px;
    }
  }
  .main-signpost {
    position: absolute;
    right: 0;
    bottom: 72px;
    left: 0;
  }
  @media (max-width: 767px) {
    .main-signpost {
      position: relative;
      bottom: 0;
    }
    &--headline {
      &--1 {
        font-size: 32px;
      }
    }
    &--slider {
      &--item {
        max-height: calc(100vh - 70px - 70px);
        padding: 100px 0 20px;
      }
    }
    .slick-arrow {
      //opacity: 0;
      //pointer-events: none;
    }
  }
  @media (max-width: 767px) and (orientation: landscape) {
    &--slider {
      &--item {
        max-height: calc(100vh - 70px);
      }
    }
  }
}

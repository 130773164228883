.detail-main {
  &--wrapper {
  }
  &--grid {
    display: flex;
    justify-content: space-between;
    font-size: 0;
  }
  &--column {
    display: inline-block;
    width: 100%;
    max-width: 450px;
    vertical-align: top;
    &:first-child {
      max-width: 500px;
    }
  }
  &--table {
    margin: 0 0 30px;
    &--price {
      margin: 0 0 35px;
      color: #18274d;
      font-size: 32px;
      font-weight: 700;
    }
  }
  h1 {
    color: #252529;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.27;
  }
  @media (max-width: 1023px) {
    &--grid {
      margin: -5px;
    }
    &--column {
      padding: 5px;
      &:nth-of-type(n) {
        width: 50%;
      }
    }
    h1 {
      font-size: 28px;
    }
  }
  @media (max-width: 767px) {
    &--grid {
      flex-direction: column-reverse;
      font-size: 16px;
    }
    &--column {
      &:nth-of-type(n) {
        display: block;
        width: 100%;
      }
    }
  }
}

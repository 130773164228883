.table {
  &--row {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    &:before {
      content: '';
      position: absolute;
      top: -100vmax;
      bottom: -100vmax;
      left: 50%;
      width: 1px;
      background: #e2e2e2;
      transform: translate(-50%, 0);
    }
  }
  &--column {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &--info {
    width: 100%;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.79;
    th,
    td {
      padding: 5px 0;
      border: none;
    }
    th {
      font-size: 14px;
      font-weight: 700;
    }
  }
  @media (max-width: 1023px) {
    &--info {
      line-height: normal;
    }
  }
}
table {
  margin: 80px 0;
  border-collapse: collapse;
  color: $color_secondary;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
}
thead {
  border: 1px solid #dfdfdf;
  background: $color_white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
}
th,
td {
  overflow: hidden;
  padding: 30px;
  border: solid 1px #e2e2e2;
}
th {
  padding: 14px 24px;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}
td {
}

.counter {
  margin: 80px 0;
  &--wrapper {
    background: #efefef;
  }
  &--slider {
    max-width: 1000px;
    margin: auto;
    padding: 0 38px;
  }
  &--item {
  }
  &--inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 164px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  &--number {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 18px 0 8px;
    color: $color_secondary;
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 48px;
      height: 4px;
      border-radius: 100px;
      background: $color_main;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      transform: translate(-50%, 0);
    }
  }
  &--text {
    display: block;
    margin: 0;
    color: rgba($color_secondary, 0.8);
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
  .slick-arrow {
    width: 38px;
    background: none;
    .icon {
      left: 10px;
      color: $color_secondary;
    }
  }
}

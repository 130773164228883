.eu-cookies {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8000;
  width: 100%;
  padding: 5px;
  color: white;
  text-align: center;
  background-color: black;
  button {
    padding: 5px;
    color: white;
    border: 0;
    background: $color_main;
    transition: all 300ms ease;
    &:hover {
      background: $color_main_dark;
    }
  }
}

.property {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 500px;
  margin: 60px auto 30px;
  &--button {
    display: flex;
    align-items: center;
    flex: 0 0 150px;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 20px 10px;
    color: $color_secondary;
    border: none;
    background: none;
    cursor: pointer;
    input:checked + & {
      box-shadow: 0 0 0 1px #18274d;
    }
  }
  &--text {
    display: block;
    margin: 10px 0 0;
    color: $color_black;
    font-weight: 400;
    text-align: center;
  }
  @media (max-width: 767px) {
    margin: 20px auto 0;
    &--button {
      flex: 0 0 110px;
    }
  }
}

// navigace
.nav {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  li {
    display: inline-block;
  }
  a {
    display: inline-block;
    padding: 2px 5px;
    text-decoration: none;
    border-radius: $radius;
    background: $color_white;
  }
}

.form {
  &--small {
    max-width: 500px;
    margin: auto;
  }
  &--row {
    margin: 0 0 20px;
  }
  &--label {
    display: block;
    margin: 0 0 5px;
    color: #808080;
    font-size: 14px;
    font-weight: 400;
    + textarea {
      margin-top: 0;
    }
  }
  &--input {
    display: block;
    width: 100%;
    padding: 10px 25px;
    color: #17140a;
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
    border: 1px solid #ededed;
    border-radius: 0;
  }
  &--textarea {
    display: block;
    width: 100%;
    margin: 30px 0 30px;
    padding: 20px 25px;
    color: #17140a;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43;
    border: 1px solid #ededed;
    border-radius: 0;
  }
  &--checkbox {
    margin: 10px 0 0;
    text-align: left;
    .checkbox {
      &--label {
        color: #17140a;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.43;
        &:before {
          border: none;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);
        }
      }
    }
  }
  &--button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0;
    padding: 13px 17px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    border: none;
    border-radius: 0;
    outline: none;
    background: linear-gradient(82deg, #fa1254 0%, #e90966 100%);
  }
  @media (max-width: 1023px) {
    &--button {
      min-height: 48px;
      padding: 10px 20px;
    }
  }
}

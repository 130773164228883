.filter {
  padding: 30px 0;
  background: $color_main;
  &--wrapper {
  }
  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 25px;
    &--inner {
      display: flex;
      align-items: center;
    }
    &--select {
      margin-left: 12px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  &--footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: -25px -20px;
    padding: 25px 0;
    color: $color_white;
    font-size: 14px;
    font-weight: 500;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      height: 1px;
      background: rgba($color_white, 0.17);
      transform: translate(0, -50%);
    }
    div {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 20px;
      background: $color_main;
    }
  }
  &--button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 13px 17px;
    color: $color_white;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    border: none;
    border-radius: 0;
    outline: none;
    background: linear-gradient(82deg, #fa1254 0%, #e90966 100%);
  }
  @media (max-width: 1199px) {
    &--header {
      flex-wrap: wrap;
      justify-content: center;
      &--inner {
        flex: 1 1 100%;
        justify-content: center;
        margin-bottom: 10px;
      }
      .input--box {
        margin-right: 12px;
      }
    }
  }
  @media (max-width: 767px) {
    &--header {
      flex-direction: column;
      &--inner {
        flex-direction: column;
      }
      &--select {
        margin-left: 0;
        &:first-child {
          margin-bottom: 8px;
        }
      }
      .input--box {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }
  @media (max-width: 479px) {
    &--footer {
      flex-direction: column;
      > div {
        &:first-child {
          margin-bottom: 12px;
        }
      }
      &:before {
        display: none;
      }
    }
  }
}

.portfolio-footer {
  margin: 50px 0 100px;
  color: #18274d;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.57;
  &--wrapper {
    border-top: 1px solid rgba(#18274d, 0.12);
  }
  &--grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }
  &--column {
    &:first-child {
      display: flex;
      align-items: center;
    }
  }
  .custom-select {
    width: auto;
    color: #18274d;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.57;
    background: none;
  }
  .select-selected {
    color: currentColor;
    border: none;
    background: none;
  }
  .select-items {
    border: none;
    div {
      color: #18274d;
      background: #fff;
    }
  }
  @media (max-width: 479px) {
    &--grid {
      flex-direction: column;
      justify-content: center;
    }
  }
}

.text-section {
  margin: 65px 0 130px;
  color: $color_secondary;
  text-align: center;
  &--wrapper {
  }
  h1 {
    margin: 0 0 10px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: currentColor;
  }
  p {
    color: #636d79;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.75;
  }
  @media (max-width: 767px) {
    margin: 65px 0;
  }
}

.breadcrumb {
  border-bottom: 1px solid rgba(#18274d, 0.12);
  ul {
    position: relative;
    display: block;
    overflow: hidden;
    max-width: 100%;
    margin: 0;
    padding: 0 30px 0 0;
    list-style: none;
    font-size: 0;
    white-space: nowrap;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      width: 30px;
      height: 100%;
      color: #fff;
      background: linear-gradient(to right, rgba(#fff, 0), #fff);
    }
  }
  li {
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    &:before {
      display: none;
    }
    a {
      position: relative;
      display: block;
      padding: 10px 37px 10px 0;
      color: #fa1254;
      font-size: 12px;
      font-weight: 400;
      text-decoration: underline;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 14px;
        width: 6px;
        height: 9px;
        background: #e3e4e9;
        transform: translate(0, -50%);

        mask-image: url('#{$imgPath}/icon-arrow-right-color.svg');
      }
    }
    > span {
      color: rgba(35, 34, 32, 0.29);
      font-size: 12px;
      font-weight: 400;
    }
  }
  strong {
    font-weight: 500;
  }
  + h1 {
    margin-top: 20px;
  }
}

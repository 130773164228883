.input {
  display: inline-block;
  flex: 0 0 auto;
  margin: 0 0 0 13px;
  padding: 0 18px;
  font-weight: bold;
  line-height: 40px;
  border: none;
  border-radius: 2px;
  background: $color_white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
  &::placeholder {
    color: #787878;
    font-size: 13px;
    font-weight: 600;
    text-align: right;
  }
  &--label {
    color: $color_white;
    font-size: 13px;
    font-weight: 500;
  }
  &--box {
    display: flex;
    align-items: center;
  }
  &--small {
    max-width: 124px;
  }
  &--50 {
    display: block;
    width: 100%;
    max-width: 50%;
    margin: auto;
  }
  &--normal {
    &::placeholder {
      color: #787878;
      font-size: 13px;
      font-weight: 500;
      text-align: left;
    }
  }
  &--grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    &--column {
      position: relative;
    }
    .input {
      width: 100%;
      margin: 0;
    }
  }
  &--after {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0, -50%);
    + .input {
      width: 100%;
      padding-right: 38px;
    }
  }
  @media (max-width: 767px) {
    &--50 {
      width: 100%;
      max-width: 320px;
    }
    &--grid {
      grid-template-columns: 1fr;
    }
  }
}

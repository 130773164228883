.is-opened .share-box {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.share {
  position: relative;
  z-index: 1007;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.share-box {
  position: absolute;
  top: 24px;
  right: 0;
  visibility: hidden;
  width: 100vw;
  max-width: 318px;
  padding: 24px;
  border: 1px solid rgba(234, 234, 234, 1);
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  cursor: default;
  transition: all 300ms ease;
  pointer-events: none;
  h3 {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    color: rgba(253, 16, 74, 1);
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;

    gap: 8px;
    svg {
      fill: currentColor;
    }
  }
  p {
    margin-bottom: 5px;
  }
  .icon {
    width: 20px;
  }
}
.share-box--input {
  position: relative;
  input {
    width: 100%;
    min-height: 40px;
    padding-right: calc(24px + 8px + 8px);
    text-overflow: ellipsis;
    border-radius: 8px;
  }
  button {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 18px;
    height: 18px;
    border: 0;
    background: transparent;
  }
}
.share-box__socials {
  display: grid;
  margin-top: 20px;

  gap: 8px;
}
.share-box__link {
  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr;
  padding: 0;
  color: rgba(14, 13, 13, 1);
  font-size: 16px;
  text-align: left;
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;

  gap: 8px;
  &:hover {
    color: $color_main;
  }
}
.share__button {
  margin: 0;
  padding: 5px 10px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1px;
    background: #ddddde;
  }
  .icon {
    width: 20px;
  }
}
